<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Large Scale Tests" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="Custom Tests and Test Design" />
        <Paragraph
          ><p>
            SMS is experienced in assisting our customers identify, design,
            perform and analyze custom test to meet specific needs and concerns.
            If a standard test exists to answer the issues, they will normally
            be suggested. However, for some instances, specialized custom
            testing is necessary to resolve critical issues.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Experimental Machining" />
        <Paragraph
          ><p>
            The test is used to simulate conditions for propellant machining
            operations.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="In-process Simulations" />
        <Paragraph
          ><p>
            Where the process is known, appropriate process simulations may be
            used. However, a change in the process may necessitate re-testing of
            some or all of the explosives used in the process. Appropriate
            process simulations may consist of modifications to the standard
            critical diameter, critical height, internal ignition, or Koenen
            tests, as well as other in-process classification tests to represent
            worst-case confinement, configuration, and/or loading in the
            process.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Large Scale Critical Diameter" />
        <Paragraph
          ><p>Larger scale version of the standard critical diameter test.</p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="Large Scale Critical Height" />
        <Paragraph
          ><p>Larger scale version of the standard critical height test.</p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Separation Ring Coupon" />
        <Paragraph
          ><p>
            This test is typically used for rocket propulsion system evaluation.
            It is used to verify the conditions of motor separation and to
            measure the explosive reaction for completeness and for unwanted
            damage.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="Skid Tests" />
        <Paragraph
          ><p>
            Varieties of skid tests have been performed. These tests consist of
            a customer-supplied skid with a sub-scale or partial process
            simulation mounted to the skid. The skid is operated remotely and a
            variety of normal and/or failure scenarios are introduced and tested
            to verify the operability and safety of the operations involved.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="TNT Equivalency" />
        <Paragraph
          ><p>
            The purpose of a TNT Equivalency Test is to establish the explosive
            response/output of a hazardous material by comparing it to the known
            explosive TNT. The Standard TNT Equivalency Test is performed on a
            relatively large scale with 1 or 2 reference tests using Comp C-4 to
            assure reliability. Side-on over-pressure sensors (long metal probes
            mounted on individual stands and pointed at the test sample) are
            strategically placed at 7 locations about the sample. Two video
            cameras are used to observe the event. Major fragments from the test
            vessel are labeled and their throw distances measured.
          </p>
          <p>
            The standard test vessel pipe assembly consists of a schedule 40
            steel pipe with a Length to diameter ratio of 1.0. A 1/8″ steel
            witness plate is welded on one end of the pipe, with the open end
            facing up. Photographs are taken of the test configuration before
            and after each event.
          </p>
          <p>
            The standard test consists of three material trials. Each trial uses
            approximately 50 pounds of sample. Two of the 50-lb trials are
            ignited with a Comp C-4 booster (shock initiation) and the third
            trial is ignited with either Comp C-4 or a bag igniter (flame
            initiation). This test is based on a worst-case scenario. Analysis
            of the data to determine TNT equivalency is included with the test
            results.
          </p>
          <p>
            The standard TNT equivalency test can be modified in several ways
            depending on customer needs. A high-speed camera may also be used to
            record the event. The booster and sample size may be adjusted. Other
            modifications can be made to this type of test to make it
            process-specific. Customer provided hoppers, totes, or containers
            may be used to provide confinement to the sample in a manufacturing
            configuration instead of the standard configuration.
            Process-specific modifications may not represent a worst-case
            material response.
          </p>
          <p>
            SMS also performs a TNT Equivalency Screening Test. This test
            consists of three trials using one pound samples in stainless steel
            pots on a witness plate. In these tests, a 45-gram booster of Comp
            C-4 is typically used. This test employs 3 probes to capture the
            overpressure data. Only one video camera records the event and still
            photos are not taken. This test provides an inexpensive evaluation
            of the TNT equivalency for materials with a critical diameter less
            than approximately 1″. The TNT Equivalency Screening Test is
            typically used with UN Class 1.1 materials.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Large Scale Tests",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS has years of experience and can assist you in identifying, designing, performing, and analyzing any large-scale tests. This includes critical height, critical diameter, in-process simulation, experimental machining, skid tests, separation ring coupon, and TNT equivalency."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
